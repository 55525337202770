export const links: TLinks = [
	{
		icon: ['fab', 'gitlab'],
		href: 'https://gitlab.com/Chifilly',
	},
	{
		icon: ['fab', 'github'],
		href: 'https://github.com/Chifilly',
	},
	{
		icon: ['fab', 'twitter'],
		href: 'https://twitter.com/chifilly',
	},
	{
		icon: ['fab', 'linkedin'],
		href: 'https://www.linkedin.com/in/adam-allsopp-356415145/',
	},
];

export type TLinks = ILink[];

interface ILink
{
	icon: string | [string, string];
	href: string;
}
