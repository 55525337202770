import { Component, Input } from '@angular/core';

import { IProject } from '../lists/projects.list';

@Component({
	selector: 'app-project',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.scss'],
})
export class ProjectComponent
{
	@Input() project: IProject;

	constructor()
	{

	}
}
