import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

// CDK


const modules: any[] = [
	// Components
	MatButtonModule,
	MatInputModule,
	MatToolbarModule,
	MatSidenavModule,
	MatTabsModule,
	MatCardModule,
	MatDividerModule,
	MatProgressBarModule,
	MatChipsModule,

	// CDK
];

@NgModule({
	imports: [
		CommonModule,
	].concat(modules),
	exports: modules,
	declarations: [],
})
export class MaterialModule
{

}
