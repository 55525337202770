import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

// Solid icons
import
{
	faUser,
	faProjectDiagram,
	faMailBulk,
	faDesktop,
	faCogs,
	faObjectGroup,
	faCode,
	faDatabase,
	faCodeBranch,
	faToolbox,
	faGamepad,
	faServer,
	faHdd,
} from '@fortawesome/free-solid-svg-icons';

// Regular icons
import
{

} from '@fortawesome/free-regular-svg-icons';

// Brand icons
import
{
	faNodeJs,
	faGitlab,
	faGithub,
	faTwitter,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

const modules: IconDefinition[] = [
	faUser,
	faProjectDiagram,
	faMailBulk,
	faDesktop,
	faCogs,
	faNodeJs,
	faObjectGroup,
	faCode,
	faDatabase,
	faCodeBranch,
	faToolbox,
	faGitlab,
	faGithub,
	faTwitter,
	faLinkedin,
	faGamepad,
	faServer,
	faHdd,
];

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [],
	declarations: [],
})
export class FontAwesomeModule
{
	constructor(private _library: FaIconLibrary)
	{
		for(let i = 0; i < modules.length; i++)
		{
			_library.addIcons(modules[i]);
		}
	}
}

