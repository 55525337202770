<div class="heading">
    <div class="image">
        <div class="placeholder">
            ugly mugshot coming soon&trade;
        </div>
    </div>
    <div class="image-text">
        <mat-toolbar
            class="head"
            color="primary"
        >
            <mat-toolbar-row class="name">Adam "Chifilly" Allsopp</mat-toolbar-row>
            <mat-toolbar-row class="statement">{{ statement }} since {{ birthday | amDateFormat : 'YYYY' }}</mat-toolbar-row>
        </mat-toolbar>
        <div class="info">
            <p>
                Hello! My name's Adam, and I go by the name "Chifilly" online.
                <br />
                I'm a full-stack developer, from Nottingham, England, and currently living in Nottingham, England.
                <br />
                I'm {{ today | amDifference : birthday : 'years' : true | floor }} years old, and I'm surprisingly getting a second older every second.
            </p>
            <p>
                Most of what I do online is <strong>coding things</strong> - primarily on the web - and <strong>playing video games</strong>. I'll mostly be focusing on the things I code.
            </p>
            <p>
                I use <strong>Angular + TypeScript</strong> for the front-end, and <strong>Node + TypeScript</strong> for the back-end the most. I plan to experiment with other frameworks in the future.
            </p>
            <p>
                I also know this website isn't fantastic right now. I couldn't figure out a good design and didn't have a lot of time to make it, but I wanted to complete it to showcase what I can do, so it's a bit crude.
                <br />
                I'll hopefully have time in the future to make it look better and be mobile-friendly.
            </p>
        </div>
        <div class="links">
            <a
                *ngFor="let link of links"
                [href]="link.href"
                color="primary"
                target="_blank"
                rel="noopener"
                mat-icon-button
            >
                <fa-icon [icon]="link.icon"></fa-icon>
            </a>
        </div>
    </div>
</div>

<mat-divider class="divider"></mat-divider>

<h1>Skills</h1>
<div class="group-container">
    <div
        class="group"
        *ngFor="let group of skills"
    >
        <mat-toolbar
            class="name"
            color="primary"
        >
            <fa-icon [icon]="group.icon"></fa-icon>
            <span class="icon-text">{{ group.name }}</span>
        </mat-toolbar>
        <div class="list-container">
            <mat-card
                *ngFor="let skill of group.skills"
                class="skill"
            >
                <mat-card-content class="title">{{ skill.name }} <span class="spacer"></span> <small>{{ skill.knowledge }}/10</small></mat-card-content>
                <mat-card-footer>
                    <mat-progress-bar
                        [value]="skill.knowledge * 10"
                        [color]="skill.knowledge < 5 ? 'warn' : 'primary'"
                    ></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>
</div>
