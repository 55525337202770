export const statements: TStatements = [
	'Throwing together websites',
	'Making poor decisions',
	'Playing video games',
	'Eating ALL the food',
	'Breaking Node',
	'Fixing Node',
	'Loving Angular',
	'Scared of changing old code',
	'Having too many ideas',
	'Trying to avoid feature creep',
];

export type TStatements = TStatement[];

type TStatement = string;
