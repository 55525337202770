<mat-card class="card">
    <mat-card-header class="header">
        <div
            class="logo"
            [ngStyle]="{
                'background-image': project.logo ? 'url(\'' + project.logo + '\')' : 'none'
            }"
            mat-card-avatar
        ></div>
        <mat-card-title class="title">
            {{ project.name }}
        </mat-card-title>
        <mat-card-subtitle class="subtitle">
            {{ project.source }}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="content">
        <p *ngFor="let line of project.description">
            {{ line }}
        </p>
    </mat-card-content>

    <mat-chip-list class="tags">
        <mat-chip
            class="tag"
            *ngFor="let tag of project.tags"
        >
            {{ tag }}
        </mat-chip>
    </mat-chip-list>

    <mat-card-actions
        class="actions"
        *ngIf="project.links && project.links.length > 0"
    >
        <a
            class="link visit"
            color="primary"
            *ngFor="let link of project.links"
            [href]="link.href"
            target="_blank"
            rel="noopener"
            mat-raised-button
        >
            {{ link.text }}
        </a>
    </mat-card-actions>
</mat-card>
