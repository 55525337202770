<nav
    class="header"
    color="accent"
    backgroundColor="primary"
    mat-tab-nav-bar
>
    <a
        mat-tab-link
        class="brand"
        routerLink="/"
    >
        <img src="assets/logo.png">
    </a>

    <span class="divider"></span>

    <ng-container *ngFor="let link of links.left">
        <a
            class="header-button"
            [routerLink]="link.href || []"
            (click)="link.function ? link.function() : null"
            #rla="routerLinkActive"
            [active]="rla.isActive"
            [routerLinkActiveOptions]="link.options || {}"
            routerLinkActive
            mat-tab-link
        >
            <fa-icon [icon]="link.icon"></fa-icon>
            <span class="icon-text">{{ link.text }}</span>
        </a>
    </ng-container>

    <span class="spacer"></span>

    <ng-container *ngFor="let link of links.right">
        <a
            class="header-button"
            [routerLink]="link.href || []"
            (click)="link.function ? link.function() : null"
            #rla="routerLinkActive"
            [active]="rla.isActive"
            [routerLinkActiveOptions]="link.options || {}"
            routerLinkActive
            mat-tab-link
        >
            <fa-icon [icon]="link.icon"></fa-icon>
            <span class="icon-text">{{ link.text }}</span>
        </a>
    </ng-container>
</nav>
