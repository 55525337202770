enum TCategory
{ // eslint-disable-line @typescript-eslint/indent
	VSC_EX = 'Visual Studio Code Extensions',
	BOT = 'Bots',
	OTHER = 'Other',
	WEB = 'Websites',
	GAME = 'Games',
}

enum TSource
{ // eslint-disable-line @typescript-eslint/indent
	OPEN = 'Open Source',
	CLOSED = 'Closed Source',
}

export const projects: TProjects = [
	{
		category: TCategory.WEB,
		projects: [
			{
				name: 'Chifilly',
				source: TSource.OPEN,
				links: [
					{
						text: 'Visit',
						href: 'https://chifilly.co.uk',
					},
					{
						text: 'Source',
						href: 'https://gitlab.com/Chifilly/chifilly',
					},
				],
				logo: 'https://chifilly.co.uk/assets/logo.png',
				description: [
					'The website you are currently viewing.',
					'This is the website where I put all the information about myself and my projects, to be able to display my skills and the things I do for fun.',
					'The source code is available for this too, to show how I code, so feel free to give it a look.',
				],
				tags: [
					'angular',
					'typescript',
					'angular material',
				],
			},
			{
				name: 'SimulTwitch',
				source: TSource.CLOSED,
				links: [
					{
						text: 'Visit',
						href: 'https://simultwitch.com',
					},
				],
				logo: 'https://simultwitch.com/assets/logo.png',
				description: [
					'SimulTwitch is a website that enables you to easily watch multiple Twitch.tv streams at the same time.',
					`I've been working on this website since around April 2018 and I'm incredibly happy with how it's progressed.
                    It started off as a thing for myself so I could watch multiple streams simultaneously, but then I decided to make it available to the public,
                    and I've just been iterating on it bit by bit.`,
					'It\'s created using Angular, as I wanted to learn Angular and TypeScript, and I love both Angular and TypeScript after using it. It also uses Angular Material for the UI framework.',
					'There are 3 parts to this project; the front-end made with Angular, the admin panel which is also made with Angular, and then the back-end server made with Node.'
				],
				tags: [
					'angular',
					'typescript',
					'angular material',
					'twitch',
					'nodejs',
				],
			},
		],
	},
	{
		category: TCategory.BOT,
		projects: [
			{
				name: 'Skelebot',
				source: TSource.OPEN,
				links: [
					{
						text: 'Source',
						href: 'https://gitlab.com/Chifilly/skelebot',
					},
				],
				description: [
					'This is a Twitch chat bot that I am currently building to essentially be for developers.',
					`It is specifically designed to allow as much customisability as possibly by exposing a lot of things to the extension system, so users can customise their bot with JavaScript easily,
                    as well as have the standard expected features of a Twitch chat bot.`,
					'It will be open source, but currently no source code has been pushed while I work on the bare necessities.',
					'NOTE: This project is currently on pause due to various reasons. I hope to continue this at some point in the future.',
				],
				tags: [
					'nodejs',
					'typescript',
					'javascript',
					'twitch',
				],
			},
			{
				name: 'Revenant Bot',
				source: TSource.CLOSED,
				links: [],
				description: [
					'This is a Discord bot made specifically for a server that runs DayZ game servers.',
					`It allows users to create a group by supplying its tag, name, description, language(s), server, logo and members during setup. Once they were happy and submitted their request, it
					would get sent to an admin-only channel for approval, and they could submit a reaction denoting whether they approved or rejected it. If approved, it would get sent to a public channel
					that listed all approved groups, and if denied would ask for a reason and that information would be sent to the group creator so they could edit it accordingly. At any time after
					approval, they could add and remove members from their group, and it would edit their group post accordingly.`,
					`Also, after approval, it would automatically create a new role with the name of the group and assign all members to it, as well as adding and removing that role from added and removed
					members after-the-fact.`,
					'It prodominantly uses Discords message embed feature to make visually-pleasing outputs.',
				],
				tags: [
					'nodejs',
					'typescript',
					'discord',
				],
			},
			{
				name: 'Game Master Bot',
				source: TSource.OPEN,
				links: [
					{
						text: 'Source',
						href: 'https://gitlab.com/Chifilly/game-master-bot',
					}
				],
				logo: 'https://assets.gitlab-static.net/uploads/-/system/project/avatar/18812324/dice.png',
				description: [
					'This is a Discord bot that I made for myself to help everyone in a DnD group keep track of the date and time of the next session.',
					`It was originally made just for me and the server I'm a part of, and was pretty bespoke, however, I realised how valuable it is so
					I decided to release the source code and clean it up to make it server-agnostic. It's still relatively rough, but passable.`,
					`It allows users to create a poll with a list of times that the other members can vote on to communicate availability, which - when the
					poll is closed - will automatically have the most popular chosen (or manually or randomly pick tied options, at the discretion of the user)
					and keep track of the time, which people could then request to see at any time, displaying the time in multiple timezones as well as a
					relative time display.`,
					'It prodominantly uses Discords message embed feature to make visually-pleasing outputs.',
				],
				tags: [
					'nodejs',
					'typescript',
					'discord',
				],
			},
		],
	},
	{
		category: TCategory.VSC_EX,
		projects: [
			{
				name: 'Angular Split',
				source: TSource.OPEN,
				links: [
					{
						text: 'View',
						href: 'https://marketplace.visualstudio.com/items?itemName=chifilly.angular-split#overview',
					},
					{
						text: 'Source',
						href: 'https://gitlab.com/Chifilly/angular-split',
					},
				],
				logo: 'https://chifilly.gallerycdn.vsassets.io/extensions/chifilly/angular-split/1.1.0/1550621495534/Microsoft.VisualStudio.Services.Icons.Default',
				description: [
					'This is a Visual Studio Code extension I created to make it easier to open the various Angular files in split view with a single key press.',
					'I found that this type of extension didn\'t exist, and I got tired of opening each one individually, so I decided to do it myself.',
					'It was the first extension I created, and it might be a bit rough, but it\'s fairly simple and gets the job done.',
				],
				tags: [
					'typescript',
					'vsc',
					'tool',
				],
			},
		],
	},
	{
		category: TCategory.GAME,
		projects: [
			{
				name: 'Becoming Ascended',
				source: TSource.CLOSED,
				links: [],
				description: [
					'This is the first video game I\'ve ever created. It\'s using the Unity engine, and it\'s a cyberpunk rogue-lite FPS.',
					'I\'ve always wanted to make a video game, and FPS games have always been a love of mine, and I wanted to create my own, and there aren\'t many cyberpunk rogue-lite games around.',
					'My plan is to make it very colourful and vibrant, but make the world feel brutal and dark, with a unique weapon system, using modularity as a core mechanic.',
					'I have no plan of when this will be available, as it\'s my first game and I\'m learning as I go, but I\'m learning a lot about C# and Unity in the process.',
					'NOTE: This project is currently on pause due to various reasons. I hope to continue this at some point in the future.',
				],
				tags: [
					'c#',
					'game',
					'unity',
					'cyberpunk',
					'fps',
				],
			},
		],
	},
];

export type TProjects = IProjectCategory[];

interface IProjectCategory
{
	category: TCategory;
	projects: IProject[];
}

export interface IProject
{
	name: string;
	source: TSource;
	links?: ILink[];
	logo?: string;
	tags?: string[];
	description: string[];
}

interface ILink
{
	text: string;
	href: string;
}
