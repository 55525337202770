import { Component } from '@angular/core';
import { Moment } from 'moment';

import * as moment from 'moment';

import { skills, TSkills } from './lists/skills.list';
import { statements, TStatements } from './lists/statements.list';
import { links, TLinks } from './lists/links.list';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.scss'],
})
export class AboutComponent
{
	public readonly today: Moment = moment();
	public readonly birthday: string = '1993-06-06';

	private readonly _refreshSeconds: number = 10;

	private _statement: string = null;

	// Lists
	public readonly skills: TSkills = skills;
	public readonly links: TLinks = links;
	private readonly _statements: TStatements = statements;

	constructor()
	{
		this._refreshStatement();
		window.setInterval(
			() =>
			{
				this._refreshStatement();
			},
			1000 * this._refreshSeconds,
		);
	}

	private _random(min: number, max: number): number
	{
		return Math.floor(Math.random() * (max - min) + min);
	}

	private _refreshStatement(): void
	{
		const newStatement = this._statements.splice(this._random(0, this._statements.length), 1)[0];

		if(this._statement !== null)
		{
			this._statements.push(this._statement);
		}

		this._statement = newStatement;
	}

	public get statement(): string
	{
		return this._statement;
	}
}
