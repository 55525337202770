import { Component } from '@angular/core';

interface ILinks
{
	left: ILink[];
	right: ILink[];
}

interface ILink
{
	href?: string;
	function?: () => void;
	text: string;
	icon: string | [string, string];
	options?: any;
}

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
{
	public readonly links: ILinks;

	constructor()
	{
		this.links = {
			left: [
				{
					href: '/',
					text: 'About Me',
					icon: 'user',
					options: { exact: true },
				},
				{
					href: '/projects',
					text: 'My Projects',
					icon: 'project-diagram',
				},
				{
					href: '/contact',
					text: 'Contact Me',
					icon: 'mail-bulk',
				},
			],
			right: [
			],
		};
	}
}
