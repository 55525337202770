import { Component } from '@angular/core';

import { projects, TProjects } from './lists/projects.list';

@Component({
	selector: 'app-projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent
{
	public readonly projects: TProjects = projects;

	constructor()
	{
	}
}
