<ng-container *ngFor="let project of projects">
    <mat-toolbar
        class="heading"
        color="primary"
    >
        {{ project.category }}
    </mat-toolbar>
    <div class="list-container">
        <app-project
            *ngFor="let item of project.projects"
            [project]="item"
        ></app-project>
    </div>
</ng-container>
