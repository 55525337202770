// Core
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modules
import { MaterialModule as App_MaterialModule } from './shared/modules/angular-material.module';
import { FontAwesomeModule as App_FontAwesomeModule } from './shared/modules/font-awesome.module';
import { MomentModule } from 'ngx-moment';

// Font Awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Components
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { HeaderComponent } from './header/header.component';
import { ProjectsComponent } from './projects/projects.component';
import { ErrorComponent } from './error/error.component';
import { ProjectComponent } from './projects/project/project.component';
import { ContactComponent } from './contact/contact.component';
import { FloorPipe } from './shared/pipes/floor.pipe';

@NgModule({
	declarations: [
		AppComponent,
		AboutComponent,
		HeaderComponent,
		ProjectsComponent,
		ErrorComponent,
		ProjectComponent,
		ContactComponent,
		FloorPipe,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,

		FontAwesomeModule,
		MomentModule,

		App_MaterialModule,
		App_FontAwesomeModule,
	],
	providers: [

	],
	bootstrap: [
		AppComponent,
	],
})
export class AppModule
{ }
