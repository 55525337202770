export const skills: TSkills = [
	{
		name: 'Front-End',
		icon: 'desktop',
		skills: [
			{
				name: 'Angular JS',
				knowledge: 10,
			},
			{
				name: 'Angular',
				knowledge: 9,
			},
			{
				name: 'Angular Material',
				knowledge: 10,
			},
			{
				name: 'Vue',
				knowledge: 0,
			},
			{
				name: 'React',
				knowledge: 0,
			},
			{
				name: 'Bootstrap',
				knowledge: 10,
			},
			{
				name: 'jQuery',
				knowledge: 8,
			},
			{
				name: 'Wordpress',
				knowledge: 7,
			},
		],
	},
	{
		name: 'Back-End',
		icon: 'cogs',
		skills: [
			{
				name: 'Node',
				knowledge: 10,
			},
			{
				name: 'REST',
				knowledge: 10,
			},
		],
	},
	{
		name: 'Web Servers',
		icon: 'server',
		skills: [
			{
				name: 'nginx',
				knowledge: 7.5,
			},
			{
				name: 'Apache',
				knowledge: 0,
			},
		],
	},
	{
		name: 'Operating Systems',
		icon: 'hdd',
		skills: [
			{
				name: 'Windows',
				knowledge: 10,
			},
			{
				name: 'Mac',
				knowledge: 0,
			},
			{
				name: 'Ubuntu',
				knowledge: 7,
			},
			{
				name: 'Debian',
				knowledge: 8,
			},
		],
	},
	{
		name: 'Node Packages',
		icon: ['fab', 'node-js'],
		skills: [
			{
				name: 'Express',
				knowledge: 9.5,
			},
			{
				name: 'socket.io',
				knowledge: 6,
			},
			{
				name: 'RxJS',
				knowledge: 6,
			},
			{
				name: 'Mongoose',
				knowledge: 7,
			},
		],
	},
	{
		name: 'Web Development',
		icon: 'object-group',
		skills: [
			{
				name: 'HTML',
				knowledge: 10,
			},
			{
				name: 'CSS',
				knowledge: 10,
			},
			{
				name: 'Sass/SCSS',
				knowledge: 9.5,
			},
			{
				name: 'Less',
				knowledge: 7,
			},
		],
	},
	{
		name: 'Languages',
		icon: 'code',
		skills: [
			{
				name: 'JavaScript',
				knowledge: 10,
			},
			{
				name: 'TypeScript',
				knowledge: 9.5,
			},
			{
				name: 'PHP',
				knowledge: 7,
			},
			{
				name: 'Python',
				knowledge: 1,
			},
			{
				name: 'Java',
				knowledge: 2,
			},
			{
				name: 'C#',
				knowledge: 6.5,
			},
		],
	},
	{
		name: 'Databases',
		icon: 'database',
		skills: [
			{
				name: 'MySQL',
				knowledge: 10,
			},
			{
				name: 'MongoDB',
				knowledge: 8,
			},
		],
	},
	{
		name: 'Source Control/Automation',
		icon: 'code-branch',
		skills: [
			{
				name: 'Git',
				knowledge: 10,
			},
			{
				name: 'Gulp',
				knowledge: 9,
			},
			{
				name: 'Grunt',
				knowledge: 7,
			},
		],
	},
	{
		name: 'CI/CD',
		icon: 'toolbox',
		skills: [
			{
				name: 'GitLab CI/CD',
				knowledge: 7,
			},
			{
				name: 'Circle CI',
				knowledge: 4,
			},
		],
	},
	{
		name: 'Game Engines',
		icon: 'gamepad',
		skills: [
			{
				name: 'Unity',
				knowledge: 7,
			},
			{
				name: 'Unreal Engine',
				knowledge: 0,
			},
			{
				name: 'Godot',
				knowledge: 0,
			},
		],
	},
];

export type TSkills = ISkillGroup[];

interface ISkillGroup
{
	name: string;
	icon: string | [string, string];
	skills: ISkill[];
}

interface ISkill
{
	name: string;
	knowledge: number;
}
